import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {Button, notification} from 'antd';
import {Trans, t} from '@lingui/macro';
import locationHOC from '../../locationProvider/locationHOC';
import 'whatwg-fetch';
import queryString from 'query-string';
import withDataHOC from '../../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../../constants/globalData';
import { CartCurrent } from '../../../constants/propTypesDefinitions';
import { formatProductName } from '../../../lib/formatting';
import { createFetchCartRemoveItems } from '../../../backend/apiCalls';
import { getQueryParamNumber } from '../../../lib/url';
import { QUERY_PARAMS } from '../../../constants/navigation';

/**
 * @dusan
 */

 function formatNumber(str) {
    return Number(str).toFixed(3);
}

function formatString(str, limit) {
    if(str != null && str.length > limit)
        return str.substr(0, limit);
    else
        return str;
}

class SendCartToOci extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        onFinish: PropTypes.func,
    };

    render() {
        const {onFinish, ociHook, location, disabled} = this.props;

        if(ociHook == null || ociHook == "")
            return null;

        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ID_CUSTOMER);

        return <FormModal
            disabled={disabled}
            openNode={
                <Button type="primary" disabled={disabled}>
                    <Trans>Odoslať cez OCI</Trans>
                </Button>
            }
            values={{customerId, ociHook}}
            onFinishSuccessful={onFinish}
            title={<Trans>Odoslať cez OCI</Trans>}
            Form={SendToOciFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odoslať košík.`)}
        />;
    }
}

export default locationHOC(SendCartToOci);

class SendToOciForm extends React.PureComponent {
    static propTypes = {
        values: PropTypes.shape({
            ociHook: PropTypes.string.isRequired,
            customerId: PropTypes.number,
        }).isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        [GLOBAL_DATA.CART_CURRENT]: CartCurrent.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    getFormFields = () => {
        const {values, [GLOBAL_DATA.CART_CURRENT]: cart} = this.props;
        const {ociHook} = values;

        if(ociHook == null || cart.lines == null || cart.lines.length == 0)
            return {};


        let formData = {};
        cart.lines.forEach((ln, idx) => {
            if(ln.customer_code != null)
                formData['NEW_ITEM-MATNR[' + idx + ']'] = formatString(ln.customer_code, 40);
        
            formData['NEW_ITEM-EXT_PRODUCT_ID[' + idx + ']'] = ln.id_product;
            formData['NEW_ITEM-DESCRIPTION[' + idx + ']'] = formatString(formatProductName(ln.designation, ln.manufacturer), 40);
            formData['NEW_ITEM-QUANTITY[' + idx + ']'] = formatNumber(ln.quantity);
            formData['NEW_ITEM-UNIT[' + idx + ']'] = 'EA';
            formData['NEW_ITEM-PRICE[' + idx + ']'] = formatNumber(ln.unit_price);
            formData['NEW_ITEM-CURRENCY[' + idx + ']'] = ln.id_currency;

            if(ln.order_code != null)
                formData['NEW_ITEM-MANUFACTMAT[' + idx + ']'] = formatString(ln.order_code, 40);
        });

        return formData;
    }

    handleSubmit = (e) => {
        const {values} = this.props;
        const {customerId} = values;

        if(this.form == null)
            return;

        e.preventDefault();
        this.setState({loading: true});

        // remove all items from cart
        createFetchCartRemoveItems(customerId)().then(() => {
            this.setState({loading: false});
            
            // cart is clear, submit the plain form
            this.form.submit();
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {loading} = this.state;
        const {ociHook} = values;
        const fields = this.getFormFields();
        return <div>
            <h5><Trans>Naozaj chcete odoslať košík cez OCI a vyprázdniť ho?</Trans></h5>
            <form 
                action={ociHook} 
                method="POST" 
                ref={node => this.form = node}
            >
                {Object.entries(fields).map(([key, val]) => {
                    return <input type="hidden" key={key} name={key} value={val}/>
                })}
            </form>
            <div className="mt-3 d-flex justify-content-end">
                <Button onClick={onClose}>
                    <Trans>Nie</Trans>
                </Button>
                <Button ref={this.first}  onClick={this.handleSubmit} loading={loading} type="primary">
                    <Trans>Áno</Trans>
                </Button>
            </div>
        </div>;
    }
}

const SendToOciFormWrapped = withDataHOC([GLOBAL_DATA.CART_CURRENT])(SendToOciForm);
