import React from 'react';
import PropTypes from 'prop-types';
import Select from '../general/Select';
import {ShoppingListDescArray} from '../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ShopingListsSelectByProps extends React.PureComponent {
    static propTypes = {
        shoppingLists: ShoppingListDescArray.isRequired,
        value: PropTypes.number,
        focus: PropTypes.bool,
        onChange: PropTypes.func,
    };

    render() {
        const {shoppingLists, focus, ...props} = this.props;
        return <Select
            {...props}
            allowClear={true}
            focus={focus}
            options={shoppingLists.map((shoppingList = {}) => {
                return {
                    label: shoppingList.name,
                    value: shoppingList.id,
                }
            })}
        />;
    }

}

export default ShopingListsSelectByProps;