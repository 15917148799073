import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import ShopingListsSelectByProps from '../../project/ShopingListsSelectByProps';
import {Button, Form, Input, Radio} from 'antd';
import {createFetchCartSave, createFetchCartSaveAs, createFetchShoppingListAll} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {navigateToParametrized} from '../../../lib/url';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import locationHOC from '../../locationProvider/locationHOC';
const FormItem = Form.Item;

/**
 * @fero
 */

class CartSave extends React.PureComponent {
   static propTypes = {
        customerId: PropTypes.number,
        disabled: PropTypes.bool,
    };

    render() {
        const {location, customerId, disabled} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            disabled={disabled}
            openNode={
                <Button disabled={disabled}>
                    <Trans>Uložiť</Trans>
                </Button>
            }
            values={{customerId}}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, ROUTES.SHOPPING_LISTS, {[QUERY_PARAMS.ID_SHOPPING_LIST]: result});
                reloadData([GLOBAL_DATA.CART_CURRENT]);
            }}
            title={<Trans>Uložit košík ako nákupný zoznam</Trans>}
            Form={CartSaveForm}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa uložiť nákupný zoznam.`)}
        />;
    }
}

class CartSaveForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showSaveAsForm: true,
        };
    };

    toggleShowSaveAsForm = (event) => {
        const newValue = event != null && event.target != null && event.target.value != null ?
            event.target.value :
            false;

        this.setState({
            showSaveAsForm: newValue,
        })
    };

    render() {
        const {onClose, onSubmitFinish, values} = this.props;
        const {showSaveAsForm} = this.state;
        return <div>
            <div className="mb-3 d-flex justify-content-center">
                <Radio.Group
                    value={showSaveAsForm}
                    onChange={this.toggleShowSaveAsForm}
                >
                    <Radio.Button value={true}><Trans>Vytvoriť nový</Trans></Radio.Button>
                    <Radio.Button value={false}><Trans>Pridať do existujúceho</Trans></Radio.Button>
                </Radio.Group>
            </div>
            <div style={showSaveAsForm ? {} : {display: 'none'}}>
                <SaveAsFormWrapped
                    onClose={onClose}
                    onSubmitFinish={onSubmitFinish}
                    values={values}
                />
            </div>
            <div style={!showSaveAsForm ? {} : {display: 'none'}}>
                <SaveFormWrapped
                    onClose={onClose}
                    onSubmitFinish={onSubmitFinish}
                    values={values}
                />
            </div>
        </div>
    }

}

class SaveForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            shoppingListsList: [],
        };
    }

    componentDidMount() {
        this.fetchShoppingListsList();
        setTimeout(() => {
            if (this.first != null)
                this.first.focus();
        }, 0)
    }

    fetchShoppingListsList = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(
            createFetchShoppingListAll(),
            {},
            (result) => {
                this.setState({
                    shoppingListsList: result
                })
            },
        );
    };

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const formValuesWithCustomer = merge(formValues, {id_customer: customerId});
                createFetchCartSave()(formValuesWithCustomer).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading, shoppingListsList} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Nákupný zoznam</Trans>}>
                {getFieldDecorator('id_list', {rules:[mandatoryRule]})(
                    <ShopingListsSelectByProps
                        shoppingLists={shoppingListsList}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Trans>
                    Produkty, ktoré sa už v nákupnom zozname nachádzajú, nebudú znovu pridané.
                    Poznámky k jednotlivým produktom z košíka budú prenesené do nákupného zoznamu.
                </Trans>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Pridať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const SaveFormWrapped = Form.create()(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(SaveForm));

class SaveAsForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.first != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                const formValuesWithCustomer = merge(formValues, {id_customer: customerId});
                this.setState({loading: true});
                createFetchCartSaveAs()(formValuesWithCustomer).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}>
                {getFieldDecorator('name', {rules:[mandatoryRule]})(
                    <Input/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Trans>Poznámky k jednotlivým produktom z košíka budú prenesené do nákupného zoznamu.</Trans>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Vytvoriť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const SaveAsFormWrapped = Form.create()(SaveAsForm);

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CartSave));