import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import {Button, Form, Radio} from 'antd';
import {createFetchCartEditRequestedAt} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import DatePicker from '../../general/DatePicker';
const FormItem = Form.Item;

/**
 * @fero
 */

class SetRequestedAt extends React.PureComponent {
   static propTypes = {
        disabled: PropTypes.bool,
        customerId: PropTypes.number,
        reload: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    };

    render() {
        const {disabled, customerId, reload, selectedItemsIds} = this.props;

        return <FormModal
            disabled={disabled}
            openNode={
                <Button disabled={disabled}>
                    <Trans>Zmeniť Dodací termín</Trans>
                </Button>
            }
            values={{customerId, selectedItemsIds}}
            onFinishSuccessful={reload}
            title={<Trans>Hromadne nastaviť Dodací termín (najskorší Dodací termín)</Trans>}
            Form={SetRequestedAtFormWrapper}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo vykonať akciu.`)}
        />;
    }
}

class SetRequestedAtForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
            selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
        }).isRequired,
    };



    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId, selectedItemsIds} = values;
        const selectedItemsChecked = selectedItemsIds != null && selectedItemsIds.length > 0 ? selectedItemsIds : undefined;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                const formValuesWithCustomer = merge(formValues, {id_customer: customerId, id_item: selectedItemsChecked});
                createFetchCartEditRequestedAt()(formValuesWithCustomer).then((response) => {
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Najskorší dátum odoslania</Trans>}>
                {getFieldDecorator('requested_at', {initialValue: null})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Prepísať už zadané dátumy</Trans>}
            >
                {getFieldDecorator('replace_existing', {initialValue: false})(
                    <Radio.Group>
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit}>
                    <Trans>Vykonať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const SetRequestedAtFormWrapper = Form.create()(SetRequestedAtForm);

export default SetRequestedAt;