import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import withDataHOC from '../../dataProvider/withDataHOC';
import QuotationDivisibilitySelect from '../../project/QuotationDivisibilitySelect';
import DatePicker from '../../general/DatePicker';
import {Trade, CustomerDetails} from '../../../constants/propTypesDefinitions';
import {Button, Form, Input, Radio} from 'antd';
import {createFetchCartMakeQuotation} from '../../../backend/apiCalls';
import {merge} from '../../../lib/object';
import {focus, customerIsSupplier} from '../../../lib/project';
import {formItemLayout, tailFormItemLayout, numberMandatoryRule} from '../../../constants/form';
import {navigateToParametrized} from '../../../lib/url';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {TRADE} from '../../../constants/trade';
import {getInputOnChangeEventValue} from '../../../lib/project';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import InputText from '../../general/InputText';
import TransportTypeSelect from '../../project/TransportTypeSelect';
import PaymentMethodSelect from '../../project/PaymentMethodSelect';
import locationHOC from '../../locationProvider/locationHOC';
const FormItem = Form.Item;
const TextArea = Input.TextArea;

/**
 * @fero
 */

class MakeQuotation extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        customerId: PropTypes.number.isRequired,
        [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: CustomerDetails.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            trade: undefined,
        }
    }

    getTrade = () => {
        const {[GLOBAL_DATA.CART_CUSTOMER_DETAILS]: customerDetails} = this.props;
        const {trade} = this.state;

        const defaultTrade = customerIsSupplier(customerDetails) ? TRADE.BUY : TRADE.SELL;
        return (trade != null ? trade : defaultTrade);
    }

    onTradeChange = (trade) => {
        this.setState({
            trade: trade,
        })
    };

    render() {
        const {location, customerId, disabled, [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: customerDetails, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        const trade = this.getTrade();
        return <FormModal
            disabled={disabled}
            backgroundColor={trade == TRADE.BUY ? '#B3E5FC' : undefined}
            openNode={
                <Button
                    disabled={disabled}
                >
                    <Trans>Vytvoriť ponuku</Trans>
                </Button>
            }
            values={{ customerId, initialTrade: trade, customerDetails }}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, ROUTES.QUOTATION_DETAILS, {[QUERY_PARAMS.ID_QUOTATION]: result});
                reload([GLOBAL_DATA.CART_CURRENT]);
            }}
            onTradeChange={this.onTradeChange}
            title={<Trans>Vytvorenie cenovej ponuky</Trans>}
            Form={MakeQuotationFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vytvoriť cenovú ponuku.`)}
        />
    }
}

export default locationHOC(
    withDataHOC([
        GLOBAL_DATA.CART_CUSTOMER_DETAILS, 
        GLOBAL_DATA.RELOAD_DATA
    ])(MakeQuotation)
);

class MakeQuotationForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number.isRequired,
            customerDetails: CustomerDetails.isRequired,
            initialTrade: Trade,
        }).isRequired,
        onTradeChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            trade: null,
            loading: false,
            transportTypeId: null,
            paymentMethodId: null,
        };
    }

    componentDidMount() {
        focus(this.first);
    }

    createHandleSubmit = (useSend) => (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchCartMakeQuotation()(
                    merge(formValues, {send: useSend, id_customer: customerId })
                ).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    onTransportTypeChanged = (newType) => {
        this.setState({transportTypeId: newType});
    };

    onPaymentMethodChanged = (newType) => {
        this.setState({paymentMethodId: newType});
    };

    render() {
        const {loading, transportTypeId, paymentMethodId, trade} = this.state;
        const {onClose, values, onTradeChange} = this.props;
        const {initialTrade, customerId, customerDetails} = values;
        const {getFieldDecorator} = this.props.form;
        return <Form>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ</Trans>}
            >
                {getFieldDecorator('trade', {
                    initialValue: initialTrade,
                    onChange: (e) => {
                        const newTrade = getInputOnChangeEventValue(e);
                        this.setState({trade: newTrade});
                        onTradeChange(newTrade);
                    },
                })(
                    <Radio.Group>
                        <Radio.Button value={TRADE.SELL}><Trans>Odoslaná</Trans></Radio.Button>
                        <Radio.Button value={TRADE.BUY}><Trans>Prijatá</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Kontakt</Trans>}>
                {getFieldDecorator('id_user', {rules: [numberMandatoryRule]})(
                    <UserSelectByCustomer
                        focus={true}
                        customerId={customerId}
                        addOption={true}
                    />
                )}
            </FormItem>
            { trade == TRADE.BUY ? 
                <FormItem
                    {...formItemLayout}
                    label={<Trans>Číslo ponuky u dodávateľa</Trans>}>
                    {getFieldDecorator('customer_reference', {})(
                        <InputText/>
                    )}
                </FormItem>
                :
                null
            }
            <FormItem
                {...formItemLayout}
                label={<Trans>Aktívna do</Trans>}>
                {getFieldDecorator('active_to', {})(
                    <DatePicker
                        placeholder={'neuvedené'}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Deliteľnosť</Trans>}>
                {getFieldDecorator('divisibility_level', {})(
                    <QuotationDivisibilitySelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spôsob dopravy</Trans>}>
                {getFieldDecorator('id_transport_type', {
                    initialValue: customerDetails.id_transport_type,
                    onChange: this.onTransportTypeChanged
                })(
                    <TransportTypeSelect paymentMethodId={paymentMethodId} allowClear={true}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spôsob platby</Trans>}>
                {getFieldDecorator('id_payment_method', {
                    initialValue: customerDetails.id_payment_method,
                    onChange: this.onPaymentMethodChanged
                })(
                    <PaymentMethodSelect transportTypeId={transportTypeId} allowClear={true}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka</Trans>}>
                {getFieldDecorator('customer_notices', {})(
                    <TextArea/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Interná poznámka</Trans>}>
                {getFieldDecorator('dealer_notices', {})(
                    <TextArea/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submitDraft" type="primary" loading={loading} onClick={this.createHandleSubmit(false)}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const MakeQuotationFormWrapped = Form.create()(MakeQuotationForm);