import React from 'react';
import Helmet from '../project/Helmet';
import CartSteps from './CartSteps';
import CartContent from './CartContent';
import CartOverview from './CartOverview';
import CartOrder from './CartOrder';
import locationHOC from '../locationProvider/locationHOC';
import {QUERY_PARAMS} from '../../constants/navigation';
import {getQueryParam, navigateToParametrized} from '../../lib/url';
import { numberOrNull } from '../../lib/number';
import { RIGHTS } from '../../constants/Rights';
import CartAccountRegister from './CartAccountRegister';
import CartAccountActivate from './CartAccountActivate';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CartPage extends React.PureComponent {
    changeStep = (step) => {
        const {location} = this.props;
        navigateToParametrized(location, {[QUERY_PARAMS.CART_STEP]: step});
    };

    render() {
        const {location} = this.props;
        const cartStep = numberOrNull(getQueryParam(location, QUERY_PARAMS.CART_STEP));
        const cartStepChecked = cartStep != null ? cartStep : 0;
        
        return <React.Fragment>
            <Helmet
                title={t`Nákupný košík`}
            />
            <CartSteps
                currentStep={cartStepChecked}
                changeStep={this.changeStep}
                steps={[
                    {
                        key: 0,
                        title: <Trans>Obsah košíka</Trans>,
                        icon: "shopping-cart",
                        Content: CartContent,
                    },
                    {
                        key: 1,
                        title: <Trans>Osobné údaje</Trans>,
                        icon: "idcard",
                        Content: CartAccountRegister,
                        rightsTo: RIGHTS.UNREGISTERED,
                    },
                    {
                        key: 2,
                        title: <Trans>Aktivácia konta</Trans>,
                        icon: "check-circle-o",
                        Content: CartAccountActivate,
                        rightsTo: RIGHTS.PENDING,
                    },
                    {
                        key: 3,
                        title: <Trans>Platba a doprava</Trans>,
                        icon: "solution",
                        Content: CartOrder,
                    },
                    {
                        key: 4,
                        title: <Trans>Zhrnutie objednávky</Trans>,
                        icon: "profile",
                        Content: CartOverview,
                        rightsTo: RIGHTS.DISTRIBUTOR,
                    },
                ]}
            />
        </React.Fragment>;
    }

}

export default locationHOC(CartPage);