import React from 'react';
import SentOrder from './cartOverview/SentOrder';
import Overview from './cartOverview/Overview';
import NavigateButton from '../general/NavigateButton';
import {locationHOC} from '../locationProvider/locationHOC';
import {QUERY_PARAMS} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class CartOverview extends React.PureComponent {

    render() {
        return <div>
            <div className="pb-3 d-flex justify-content-between">
                <NavigateButton
                    queryParams={{[QUERY_PARAMS.CART_STEP]: 3}}
                >
                    <Trans>Späť na spôsob dopravy a platby</Trans>
                </NavigateButton>
                <SentOrder/>
            </div>
            <Overview/>
        </div>;
    }

}

export default CartOverview;