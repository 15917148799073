import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import { PredictionMethod } from '../../constants/propTypesDefinitions';

/**
 * @fero
 */

class PredictionMethodSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.PREDICTION_METHODS]: PropTypes.arrayOf(PredictionMethod.isRequired).isRequired,
        value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
    };

    componentDidMount() {
        const {onChange, value, allowClear} = this.props;
        if (value == null && !allowClear) {
            const methods = this.props[GLOBAL_DATA.PREDICTION_METHODS];
            if(methods.length > 0)
                onChange(methods[0].name);
        }
    }

    render() {
        const { [GLOBAL_DATA.PREDICTION_METHODS]: methods, ...props } = this.props;
        return <Select
            {...props}
            options={methods.map(obj => {
                return {
                    label: obj.label,
                    value: obj.name,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.PREDICTION_METHODS])(PredictionMethodSelect);