import React from 'react';
import PropTypes from 'prop-types';
import RemoveCartItem from './RemoveCartItem';
import AvailabilityIcon from './AvailabilityIcon';
import cartContentLine from './CartContentLine';
import InputQuantity from '../../../project/InputQuantity';
import InputUnitPrice from '../../../project/InputUnitPrice';
import Link from '../../../navigation/Link';
import {formatPercentage, formatUnitPrice, formatQuantityPerPackage, formatQuantity} from '../../../../lib/formatting';
import {QUERY_PARAMS, ROUTES} from '../../../../constants/navigation';
import {CartLine} from '../../../../constants/propTypesDefinitions';
import DatePicker from '../../../general/DatePicker';
import RightsWrapper from '../../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import ProductMoves from '../../../project/ProductMoves';
import InputTextArea from '../../../general/InputTextArea';
import PurchasePricesModal from '../../../project/productTable/PurchasePricesModal';
import CustomerProductCodeWithEditation from '../../../project/CustomerProductCodeWithEditation';
import ProductAvailability from '../../../project/productTable/ProductAvailability';
import InputNumber from '../../../general/InputNumber';
import PackageTypeSelect from '../../../project/PackageTypeSelect';
import { Checkbox } from 'antd';
import { getInputOnChangeEventChecked } from '../../../../lib/project';
import { currentDate } from '../../../../lib/date';
import StockOptionsSelect from '../../../project/StockOptionsSelect';
import Price from '../../../general/Price';
import UnitPrice from '../../../general/UnitPrice';
import SpecialTransportIcon from '../../../project/SpecialTransportIcon';


/**
 * @fero
 */

class CartContentRow extends React.PureComponent {
    static propTypes = {
        data: CartLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number,
        onQuantityChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
        isSelected: PropTypes.bool,
        onSelect: PropTypes.func,
        onStockChange: PropTypes.func.isRequired,
        onAtomicChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, customerId, reload, onQuantityChange, onPriceChange, onPackageTypeChange,
            onRequestedAtChange, onNoticeChange, onDealerNoticeChange, onSequenceChange,
            isSelected, onSelect, onStockChange, onAtomicChange
        } = this.props;
        return <tr>
            { onSelect != null ? 
                <td className="text-center">
                    <Checkbox
                        onChange={(e) => {
                            onSelect(getInputOnChangeEventChecked(e))
                        }}
                        checked={isSelected}
                    />
                </td> : null
            }
            <td>
                <InputNumber
                    className="text-right"
                    size="small"
                    value={data.sequence}
                    onChange={onSequenceChange}
                />
            </td>
            <td>
                <div className="my-1 d-flex flex-wrap align-items-center">
                    <Link
                        className="mx-2 text-dark"
                        to={ROUTES.PRODUCT_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                        title={<Trans>Zobraziť detail produktu</Trans>}
                    >
                        {data.designation}
                    </Link>
                    <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                        <CustomerProductCodeWithEditation
                            reload={reload}
                            productId={data.id_product}
                            customerId={customerId}
                            code={data.customer_code}
                            isInline={true}
                        />
                    </RightsWrapper>
                </div>
                { data.order_code != null ? 
                    <div className="table-subdata ml-2"><Trans>Obj. č.:</Trans>{' '}{data.order_code}</div> : 
                    null
                }
                <div className="d-flex">
                    <ProductMoves 
                        customerId={customerId}
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                        quotationsCount={data.quotations_count}
                    />
                </div>
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                {data.manufacturer}
            </td>
            <td className="text-right">
                <InputQuantity
                    size="small"
                    className="text-right"
                    value={Number(data.package_quantity)}
                    onChange={onQuantityChange}
                    unit={
                        <PackageTypeSelect
                            size="small"
                            value={data.id_package_type}
                            onChange={onPackageTypeChange}
                            packageInfo={data}
                        />
                    }
                />
                <div className="table-subdata">
                    {formatQuantityPerPackage(data.quantity_bulk, data.quantity_units, 'kart')}
                </div>
                <div className="table-subdata">
                    {formatQuantityPerPackage(data.quantity_pallet, data.quantity_units, 'pal')}
                </div>
                { data.only_bulk_purchase > 0 ? 
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <div className="color-red table-subdata">
                            <Trans>Min. nákup:</Trans>
                            {' '}
                            {formatQuantity(data.quantity_bulk, data.quantity_units)}
                        </div>
                    </RightsWrapper> : 
                    null
                }
                <Checkbox
                    checked={data.is_atomic == 1}
                    onChange={(e) => {
                        const selected = getInputOnChangeEventChecked(e);
                        onAtomicChange(selected ? 1 : 0);
                    }}
                >
                    <Trans>iba v celku</Trans>
                </Checkbox>
            </td>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <td className="text-center">
                    <AvailabilityIcon isAvailable={data.is_available}/>
                    <StockOptionsSelect
                        value={data.id_stock}
                        onChange={onStockChange}
                        label={data.stock}
                        productId={data.id_product}
                        customerId={data.id_customer}
                        quantityUnits={data.quantity_units}
                    />
                </td>
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td className="text-right">
                    <ProductAvailability
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                        quantityAvailable={data.quantity_available}
                        quantityOrdered={data.quantity_ordered}
                        quantityUnits={data.quantity_units}
                    />
                    <StockOptionsSelect
                        value={data.id_stock}
                        onChange={onStockChange}
                        label={data.stock}
                        productId={data.id_product}
                        customerId={data.id_customer}
                        quantityUnits={data.quantity_units}
                    />
                </td>
            </RightsWrapper>
            <td className="text-right">
                <RightsWrapper to={RIGHTS.WORKER}>
                    <UnitPrice price={data.package_price} units={data.package_type} currency={data.id_currency}/>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.DEALER}>
                    <InputUnitPrice
                        size="small"
                        className="text-right"
                        hasError={data.package_price == null}
                        value={data.package_price}
                        isSpecial={data.is_special == 1}
                        currency={data.id_currency}
                        onChange={onPriceChange}
                    />
                    { data.quantity_per_package != null ? 
                        <div className="table-subdata">
                            {"("}
                            <UnitPrice 
                                price={data.unit_price} 
                                units={data.quantity_units} 
                                currency={data.id_currency}
                                nullOption={<Trans>na dopyt</Trans>}
                            />
                            {")"}
                        </div> 
                        : null
                    }
                    <PurchasePricesModal
                        size="small"
                        className="full-size-width mt-1"
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                    />
                </RightsWrapper>
            </td>
            <td className="text-right overflow-wrap-break-word">
                <Price
                    price={data.line_price}
                    isSpecial={data.is_special == 1}
                    nullOption={<Trans>na dopyt</Trans>}
                />
            </td>
            <td className="text-right">{formatPercentage(data.tax_rate)}</td>
            <td className="text-right overflow-wrap-break-word">
                <Price
                    price={data.total_price}
                    isSpecial={data.is_special == 1}
                    nullOption={<Trans>na dopyt</Trans>}
                />
            </td>
            <td>
                <DatePicker
                    size="small"
                    value={data.requested_at}
                    min={currentDate()}
                    onChange={onRequestedAtChange}
                />
            </td>
            <td>
                <InputTextArea
                    size="small"
                    value={data.customer_notice}
                    onChange={onNoticeChange}
                />
            </td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <InputTextArea
                        size="small"
                        value={data.dealer_notice}
                        onChange={onDealerNoticeChange}
                    />
                </td>
            </RightsWrapper>
            <td>
                <div className="d-flex justify-content-center">
                    <RemoveCartItem reload={reload} cartLine={data}/>
                </div>
            </td>
        </tr>;
    }

}

export default cartContentLine(CartContentRow);