import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import RegisterForm from '../login/RegisterForm';
import { navigateToParametrized } from '../../lib/url';
import locationHOC from '../locationProvider/locationHOC';
import { Location } from '../../constants/propTypesDefinitions';
import { ASYNC_STATE } from '../../constants/AsyncState';
import { notification } from 'antd';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { GLOBAL_DATA } from '../../constants/globalData';

/**
 * @fero
 */

class CartAccountRegister extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: PropTypes.func.isRequired,
    };
    
    render() {
        const {
            location, 
            [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: reloadRights,
        } = this.props;

        return <div className="d-flex justify-content-center full-size-height overflow-y-auto">
            <div className="p-3 info-attribute-group">
                <RegisterForm
                    onClose={() => navigateToParametrized(location, null, {[QUERY_PARAMS.CART_STEP]: 0})}
                    onSubmitFinish={(response) => {
                            if(response.asyncState == ASYNC_STATE.SUCCEEDED) 
                            {
                                // refresh, then navigate
                                reloadRights(ROUTES.CART, {[QUERY_PARAMS.CART_STEP]: 2});
                            }
                            else 
                            {
                                notification['error']({
                                    duration: 10,
                                    message: response.result != null ? response.result.message : null,
                                });
                            }
                                
                        }
                    }
                    cancelLabel={<Trans>Späť</Trans>}
                />
            </div>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS])(locationHOC(CartAccountRegister));