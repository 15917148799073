import React from 'react';
import {Trans, t} from '@lingui/macro';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import { navigateToParametrized } from '../../lib/url';
import ActivationForm from '../account/info/form/ActivationForm';
import withDataHOC from '../dataProvider/withDataHOC';
import { GLOBAL_DATA } from '../../constants/globalData';
import { UserInfo, Location } from '../../constants/propTypesDefinitions';
import locationHOC from '../locationProvider/locationHOC';
import { ASYNC_STATE } from '../../constants/AsyncState';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import { notification } from 'antd';
import { RIGHTS } from '../../constants/Rights';

/**
 * @fero
 */

class CartAccountActivate extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
    };

    componentDidMount() {
        const {[SESSION_ATTRIBUTES.RIGHTS] : rights, location} = this.props;
        if(rights >= RIGHTS.CUSTOMER) {
            notification['info']({
                message: t`Váš účet bol aktivovaný.`,
            });
            navigateToParametrized(location, null, {[QUERY_PARAMS.CART_STEP]: 3});
        }
    }

    render() {
        const {
            location, 
            [GLOBAL_DATA.ACCOUNT_INFO]: userInfo, 
            [SESSION_ATTRIBUTES.RELOAD_RIGHTS]: reloadRights
        } = this.props;
        return <div className="d-flex justify-content-center full-size-height overflow-y-auto">
            <div className="p-3 info-attribute-group">
                <ActivationForm
                    values={{userInfo}}
                    onClose={() => navigateToParametrized(location, null, {[QUERY_PARAMS.CART_STEP]: 0})}
                    onSubmitFinish={(response) => {
                            if(response.asyncState == ASYNC_STATE.SUCCEEDED) 
                            {
                                // reload, then navigate to cart
                                reloadRights(ROUTES.CART, {[QUERY_PARAMS.CART_STEP]: 3});
                            }
                            else 
                            {
                                notification['error']({
                                    duration: 10,
                                    message: response.result != null ? response.result.message : null,
                                })
                            }
                        }
                    }
                    cancelLabel={<Trans>Späť</Trans>}
                />
            </div>
        </div>;
    }

}

export default withSessionHOC([SESSION_ATTRIBUTES.RELOAD_RIGHTS, SESSION_ATTRIBUTES.RIGHTS])(
    withDataHOC([GLOBAL_DATA.ACCOUNT_INFO])(
        locationHOC(CartAccountActivate)
    )
);