import React from 'react';
import PropTypes from 'prop-types';
import ProductSelect from '../project/ProductSelect';
import Link from '../navigation/Link';
import {QUERY_PARAMS, ROUTES} from '../../constants/navigation';
import {Trans, t} from '@lingui/macro';
import { formatProductName } from '../../lib/formatting';

/**
 * Component for display of product name with possibility to edit it in requests, quotations and orders.
 *
 * @fero
 */

class ProductEditableRQO extends React.Component {
    static propTypes = {
        productId: PropTypes.number,//id of saved product
        productDesignation: PropTypes.string,//name of saved product
        productManufacturer: PropTypes.string,
        onProductIdChange: PropTypes.func,
        onProductDataChange: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: "text-dark"
    };


    render() {
        const {productId, productDesignation, productManufacturer, onProductIdChange, onProductDataChange, className} = this.props;
        if (onProductIdChange != null) {
            return <ProductSelect
                size="small"
                value={productId}
                onChange={onProductIdChange}
                onChangeWithData={onProductDataChange}
                initSearchText={productDesignation}
                allowClear={false}
                className={className}
            />
        } else {
            return productId != null ? <Link
                className={className}
                to={ROUTES.PRODUCT_DETAILS}
                queryParams={{[QUERY_PARAMS.ID_PRODUCT]: productId}}
                title={<Trans>Zobraziť detail produktu</Trans>}
            >
                {formatProductName(productDesignation, productManufacturer)}
            </Link> : null
        };
    }
}

export default ProductEditableRQO;