import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import Address from '../../general/Address';
import NamePrice from '../../general/NamePrice';
import EmailLink from '../../general/EmailLink';
import PhoneLink from '../../general/PhoneLink';
import {getAddressById} from '../../../lib/project';
import {getQueryParam} from '../../../lib/url';
import {formatUserInfoName} from '../../../lib/formatting';
import {QUERY_PARAMS} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {UserInfo,UserAddress, CustomerDetails} from '../../../constants/propTypesDefinitions';
import {Trans, t} from '@lingui/macro';
import { createFetchTransportTypes, createFetchPaymentMethods } from '../../../backend/apiCalls';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import { TRANSPORT_TYPE } from '../../../constants/transportTypes';

/**
 * @fero
 */

class OverviewInfo extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ACCOUNT_INFO]: UserInfo.isRequired,
        [GLOBAL_DATA.ACCOUNT_ADDRESSES]: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.COMPANY_ADDRESSES]: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: CustomerDetails.isRequired,
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            transportTypes: [],
            paymentMethods: [],
        };
    }

    fetchTransportTypes = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchTransportTypes(),
            {},
            (result) => {
                this.setState({transportTypes: result});
            }
        );
    };

    fetchPaymentMethods = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        fetchHandler(
            createFetchPaymentMethods(),
            {},
            (result) => {
                this.setState({paymentMethods: result});
            }
        );
    };

    componentDidMount() {
        this.fetchTransportTypes();
        this.fetchPaymentMethods();
    }

    render() {
        const {
            location, 
            [GLOBAL_DATA.ACCOUNT_INFO]: accountInfo,
            [GLOBAL_DATA.ACCOUNT_ADDRESSES]: accountAddresses,
            [GLOBAL_DATA.COMPANY_ADDRESSES]: companyAddresses
        } = this.props;
        const {transportTypes, paymentMethods} = this.state;

        const transportTypeId = getQueryParam(location, QUERY_PARAMS.ID_TRANSPORT_TYPE);
        const paymentMethodId = getQueryParam(location, QUERY_PARAMS.ID_PAYMENT_METHOD);
        const transportType = getAddressById(transportTypes, transportTypeId);
        const paymentMethod = getAddressById(paymentMethods, paymentMethodId);

        const deliveryAddressId = getQueryParam(location, QUERY_PARAMS.ID_DELIVERY_ADDRESS);
        const invoiceAddressId = getQueryParam(location, QUERY_PARAMS.ID_INVOICE_ADDRESS);
        const invoiceAddress = getAddressById(accountAddresses, invoiceAddressId);
        const deliveryAddressList = (transportTypeId == TRANSPORT_TYPE.AT_STORE) ? companyAddresses : accountAddresses;
        const deliveryAddress = getAddressById(deliveryAddressList, deliveryAddressId);
        const deliveryAddressChecked = deliveryAddress != null ? deliveryAddress : {};
        const invoiceAddressChecked = invoiceAddress != null ? invoiceAddress : {};       

        const customerReference = getQueryParam(location, QUERY_PARAMS.CUSTOMER_REFERENCE);
        const customerNotices = getQueryParam(location, QUERY_PARAMS.CUSTOMER_NOTICES);
        const isDivisible = getQueryParam(location, QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE);

        const customerDetails = this.props[GLOBAL_DATA.CART_CUSTOMER_DETAILS];

        return <div className="d-flex flex-wrap justify-content-center">
            <InfoAttributeGroup
                attributes={[
                    {
                        title: <Trans>Kontakt:</Trans>,
                        value: formatUserInfoName(accountInfo)
                    },
                    {
                        title: <Trans>Email:</Trans>,
                        value: <EmailLink email={accountInfo.e_mail}/>
                    },
                    {
                        title: <Trans>Telefón:</Trans>,
                        value: <PhoneLink phone={accountInfo.phone}/>
                    },
                    {
                        title: <Trans>Organizácia:</Trans>,
                        value: accountInfo.organization
                    },
                    {
                        title: <Trans>IČO:</Trans>,
                        value: accountInfo.ico
                    },
                    {
                        title: <Trans>IČ DPH:</Trans>,
                        value: accountInfo.ic_dph
                    }
                ]}
            />
            <InfoAttributeGroup
                attributes={[
                    {
                        title: <Trans>Dodacia adresa: </Trans>,
                        value: <Address address={deliveryAddressChecked}/>
                    },
                    {
                        title: <Trans>Fakturačná adresa: </Trans>,
                        value: <Address address={invoiceAddressChecked}/>
                    },
                    {
                        title: <Trans>Vaše číslo objednávky: </Trans>,
                        value: customerReference != null ? customerReference : <Trans>neuvedené</Trans>
                    }
                ]}
            />
            <InfoAttributeGroup
                attributes={[
                    {
                        title: <Trans>Spôsob dopravy: </Trans>,
                        value: <NamePrice namePrice={transportType} forFree={customerDetails.free_transport ? true : false}/>
                    },
                    {
                        title: <Trans>Spôsob platby: </Trans>,
                        value: <NamePrice namePrice={paymentMethod}/>
                    },
                    {
                        title: <Trans>Doručenie po častiach: </Trans>,
                        value: isDivisible == true ? <Trans>Áno</Trans> : <Trans>Nie</Trans>
                    },
                    {
                        title: <Trans>Poznámka: </Trans>,
                        value: <pre>{customerNotices}</pre>
                    }
                ]}
            />
        </div>;
    }

}


export default withSessionHOC()(
    locationHOC(
        withDataHOC([
            GLOBAL_DATA.ACCOUNT_INFO,
            GLOBAL_DATA.ACCOUNT_ADDRESSES,
            GLOBAL_DATA.COMPANY_ADDRESSES,
            GLOBAL_DATA.CART_CUSTOMER_DETAILS,
            GLOBAL_DATA.FETCH_HANDLER,
        ])
        (OverviewInfo)
    )
)