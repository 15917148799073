import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import CustomersSelect from '../../project/CustomersSelect';
import {Button, Form, Radio} from 'antd';
import {createFetchCartMove} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {navigateToParametrized} from '../../../lib/url';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {QUERY_PARAMS} from '../../../constants/navigation';
import locationHOC from '../../locationProvider/locationHOC';
const FormItem = Form.Item;

/**
 * @fero
 */

class CartMove extends React.PureComponent {
   static propTypes = {
        customerId: PropTypes.number,
        disabled: PropTypes.bool,
    };

    render() {
        const {customerId, location, disabled} = this.props;
        return <FormModal
            disabled={disabled}
            openNode={
                <Button disabled={disabled}>
                    <Trans>Presunúť do iného košíka</Trans>
                </Button>
            }
            values={{customerId}}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, null, {[QUERY_PARAMS.ID_CUSTOMER]: result});
            }}
            title={<Trans>Presunúť všetko do košíka inej organizácie</Trans>}
            Form={CartMoveFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa presunúť košík.`)}
        />;
    }
}

class CartMoveForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
        }).isRequired,
    };

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const formValuesWithCustomer = merge(formValues, {id_src_customer: customerId});
                createFetchCartMove()(formValuesWithCustomer).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Presunúť do košíka</Trans>}>
                {getFieldDecorator('id_dst_customer', {rules:[mandatoryRule]})(
                    <CustomersSelect/>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Kopírovať ceny</Trans>}
            >
                {getFieldDecorator('copy_prices', {
                    initialValue: false,
                })(
                    <Radio.Group>
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit}>
                    <Trans>Vykonať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const CartMoveFormWrapped = Form.create()(withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(CartMoveForm));

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(CartMove));