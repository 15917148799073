import React from 'react';
import {Icon} from 'antd';
import PropTypes from 'prop-types';
import Tooltip from '../../../general/Tooltip';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class AvailabilityIcon extends React.PureComponent {
    static propTypes = {
        isAvailable: PropTypes.number,
    };

    render() {
        const {isAvailable} = this.props;
        if(isAvailable) 
        	return <Tooltip title={<Trans>Dostatočné množstvo na sklade</Trans>}>
                <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
            </Tooltip>;
        else
        	return <Tooltip title={<Trans>Nedostatočné množstvo na sklade</Trans>}>
        		<Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ffbf00"/>
        	</Tooltip>;
    }
}

export default AvailabilityIcon;