import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import {Button, Form} from 'antd';
import {createFetchCartShare} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {navigateToParametrized} from '../../../lib/url';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import locationHOC from '../../locationProvider/locationHOC';
import InputText from '../../general/InputText';
const FormItem = Form.Item;

/**
 * @fero
 */

class CartShare extends React.PureComponent {
   static propTypes = {
        customerId: PropTypes.number,
        disabled: PropTypes.bool,
    };

    render() {
        const {customerId, location, disabled} = this.props;
        return <FormModal
            disabled={disabled}
            openNode={
                <Button icon="share-alt" disabled={disabled}>
                    <span className="ml-2">
                        <Trans>Zdieľať</Trans>
                    </span>
                </Button>
            }
            values={{customerId}}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, ROUTES.SHOPPING_LISTS, {[QUERY_PARAMS.ID_SHOPPING_LIST]: result});
            }}
            title={<Trans>Zdieľať nákupný košík e-mailom</Trans>}
            Form={CartShareFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa zdieľať košík.`)}
        />;
    }
}

class CartShareForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerId: PropTypes.number,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        const {customerId} = values;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const formValuesWithCustomer = merge(formValues, {id_customer: customerId});
                createFetchCartShare()(formValuesWithCustomer).then((response) => {
                    this.setState({loading: false});
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {onClose} = this.props;
        const {loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Odoslať na e-mail</Trans>}>
                {getFieldDecorator('e_mail', {rules:[mandatoryRule]})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka (názov)</Trans>}>
                {getFieldDecorator('name', {})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Odoslať</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const CartShareFormWrapped = Form.create()(CartShareForm);

export default locationHOC(CartShare);