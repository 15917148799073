import React from 'react';
import CartContentTable from './cartContent/CartContentTable';
import withDataHOC from '../dataProvider/withDataHOC';
import {locationHOC} from '../locationProvider/locationHOC';
import {getQueryParam} from '../../lib/url';
import {numberOrNull} from '../../lib/number';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../constants/globalData';
import {QUERY_PARAMS} from '../../constants/navigation';
import CartContentActions from './cartContent/CartContentActions';

/**
 * @fero
 */

class CartContent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedItemsIds: null,
        };
    }

    render() {
        const {location, [GLOBAL_DATA.CART_CURRENT]: cartCurrent, [GLOBAL_DATA.RELOAD_DATA]: reloadData} = this.props;
        const {selectedItemsIds} = this.state;
        const cartNotEmpty = cartCurrent != null && cartCurrent.lines != null && cartCurrent.lines.length > 0;
        const customerId = numberOrNull(getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER));

        return <div>
            <CartContentActions
                customerId={customerId}
                cartCurrent={cartCurrent}
                reload={() => reloadData([GLOBAL_DATA.CART_CURRENT])}
                selectedItemsIds={selectedItemsIds}
            />
            <div>
                { cartNotEmpty ? 
                    <CartContentTable
                        selectedItemsIds={selectedItemsIds}
                        setSelected={(ids) => this.setState({selectedItemsIds: ids})}
                    /> : 
                    <h1 className="text-center"><Trans>Košík je prázdny.</Trans></h1>
                }
            </div>
        </div>;
    }
}

export default locationHOC(withDataHOC([GLOBAL_DATA.CART_CURRENT, GLOBAL_DATA.RELOAD_DATA])(CartContent));