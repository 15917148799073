import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import withDataHOC from '../../dataProvider/withDataHOC';
import createFormHOC from '../../fetch/createFormHOC';
import {navigateToParametrized} from '../../../lib/url';
import {Button, Form, Input, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {TRADE} from '../../../constants/trade';
import {createFetchCartMakeRequest} from '../../../backend/apiCalls';
import {FormHOCPropsObject, Trade, CustomerDetails} from '../../../constants/propTypesDefinitions';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import InputText from '../../general/InputText';
import { getInputOnChangeEventValue, customerIsSupplier } from '../../../lib/project';
import locationHOC from '../../locationProvider/locationHOC';
const FormItem = Form.Item;
const TextArea = Input.TextArea;

/**
 * @fero
 */

class MakeRequestDealer extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        customerId: PropTypes.number.isRequired,
        [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: CustomerDetails.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            trade: undefined,
        };
    }

    getTrade = () => {
        const {[GLOBAL_DATA.CART_CUSTOMER_DETAILS]: customerDetails} = this.props;
        const {trade} = this.state;

        const defaultTrade = customerIsSupplier(customerDetails) ? TRADE.BUY : TRADE.SELL;
        return (trade != null ? trade : defaultTrade);
    }

    onTradeChange = (trade) => {
        this.setState({
            trade: trade,
        })
    };

    render() {
        const {location, [GLOBAL_DATA.RELOAD_DATA]: reload, disabled, customerId} = this.props;
        const trade = this.getTrade();
        return <FormModal
            disabled={disabled}
            backgroundColor={trade == TRADE.BUY ? '#B3E5FC' : undefined}
            openNode={
                <Button
                    disabled={disabled}
                >
                    <Trans>Vytvoriť dopyt</Trans>
                </Button>
            }
            values={{customerId, trade}}
            onTradeChange={this.onTradeChange}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, ROUTES.REQUEST_DETAILS, {[QUERY_PARAMS.ID_REQUEST]: result});
                reload([GLOBAL_DATA.CART_CURRENT]);
            }}
            title={<Trans>Vytvorenie dopytu</Trans>}
            Form={MakeRequestDealerFormWrapped}
            fetchFunc={createFetchCartMakeRequest()}
            Response={null}
            Failed={generalFailedPC(t`Dopyt sa nepodarilo odoslať.`)}
        />;
    }
}

export default locationHOC(
    withDataHOC([
        GLOBAL_DATA.CART_CUSTOMER_DETAILS, 
        GLOBAL_DATA.RELOAD_DATA
    ])(MakeRequestDealer)
);

class MakeRequestDealerForm extends React.PureComponent {
    static propTypes = {
        ...FormHOCPropsObject,
        values: PropTypes.shape({
            customerId: PropTypes.number.isRequired,
            trade: Trade.isRequired,
        }).isRequired,
        onTradeChange: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    render() {
        const {loading, createSubmit, onClose, onTradeChange, values = {}} = this.props;
        const {customerId, trade} = values;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={createSubmit({send: false, id_customer: customerId})}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Typ</Trans>}
            >
                {getFieldDecorator('trade', {
                    initialValue: trade,
                    onChange: (e) => {
                        onTradeChange(getInputOnChangeEventValue(e))
                    }})(
                    <Radio.Group>
                        <Radio.Button value={TRADE.BUY}><Trans>Odoslaný dopyt</Trans></Radio.Button>
                        <Radio.Button value={TRADE.SELL}><Trans>Prijatý dopyt</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Kontakt</Trans>}
            >
                {getFieldDecorator('id_user', {rules: [mandatoryRule]})(
                    <UserSelectByCustomer
                        focus={true}
                        customerId={customerId}
                        addOption={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Číslo dopytu zákazníka</Trans>}>
                {getFieldDecorator('customer_reference', {})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka</Trans>}>
                {getFieldDecorator('customer_notices', {})(
                    <TextArea
                        min={2}
                        max={20}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Interná poznámka</Trans>}>
                {getFieldDecorator('dealer_notices', {})(
                    <TextArea
                        min={2}
                        max={20}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Trans>Poznámky k jednotlivým produktom z košíka budú prenesené do dopytu.</Trans>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading}>
                    <Trans>Vytvoriť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }
}

const MakeRequestDealerFormWrapped = createFormHOC()(MakeRequestDealerForm);