import React from 'react';
import PropTypes from 'prop-types';
import OverviewInfo from './OverviewInfo';
import CartContentTable from '../cartContent/CartContentTable';
import PriceWhole from '../../project/PriceWhole';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {CartCurrent} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class Overview extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.CART_CURRENT]: CartCurrent.isRequired,
    };

    render() {
        const cartCurrent = this.props[GLOBAL_DATA.CART_CURRENT];
        return <div className="full-size-height d-flex flex-column">
            <div className="flex-item-static">
                <OverviewInfo/>
            </div>
            <div className="flex-item-dynamic-1">
                <CartContentTable cartCurrent={cartCurrent}/>
            </div>
        </div>;
    }

}

export default withDataHOC([GLOBAL_DATA.CART_CURRENT])(Overview);