import React from 'react';
import PropTypes from 'prop-types';
import MakeRequestDealer from './MakeRequestDealer';
import MakeRequestCustomer from './MakeRequestCustomer';
import MakeQuotation from './MakeQuotation';
import CartSave from './CartSave';
import NavigateButton from '../../general/NavigateButton';
import {Trans, t} from '@lingui/macro';
import {RIGHTS} from '../../../constants/Rights';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import CartMove from './CartMove';
import PredictionFillCart from './PredictionFillCart';
import CartShare from './CartShare';
import { CartCurrent } from '../../../constants/propTypesDefinitions';
import PageActionsLayout from '../../general/PageActionsLayout';
import CartClear from './CartClear';
import SetRequestedAt from './SetRequestedAt';
import SendCartToOci from './SendCartToOci';
import CartImport from './CartImport';

/**
 * @fero
 */

class CartContentActions extends React.PureComponent {
    static propTypes = {
        customerId: PropTypes.number,
        cartCurrent: CartCurrent.isRequired,
        reload: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    }
    render() {
        const {customerId, cartCurrent, reload, selectedItemsIds} = this.props;
        const cartNotEmpty = cartCurrent != null && cartCurrent.lines != null && cartCurrent.lines.length > 0;
        const cartHasPrice = cartCurrent != null && cartCurrent.sum_netto != null && cartCurrent.sum_brutto != null;
        const nextStepDisabled = !(cartNotEmpty && cartHasPrice);
        return <PageActionsLayout
            back={{
                to: ROUTES.HOME,
                title: <Trans>Produkty</Trans>
            }}
            actions={[
                {
                    node: <CartClear
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                        reload={reload}
                        selectedItemsIds={selectedItemsIds}
                    />
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    node: <CartImport
                        customerId={customerId}
                        reload={reload}
                    />
                },
                {
                    node: <SetRequestedAt
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                        reload={reload}
                        selectedItemsIds={selectedItemsIds}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <CartMove
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <PredictionFillCart
                        reload={reload}
                    />
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    node: <CartShare
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                    />
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    node: <CartSave
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                    />
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    rightsTo: RIGHTS.DISTRIBUTOR,
                    node: <MakeRequestCustomer
                        reload={reload}
                    />
                },
                {
                    rightsFrom: RIGHTS.DEALER,
                    node: <MakeRequestDealer
                        customerId={customerId}
                    />
                },
                {
                    rightsFrom: RIGHTS.MARKETING,
                    node: <MakeQuotation
                        customerId={customerId}
                        disabled={!cartNotEmpty}
                    />
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    node: <SendCartToOci
                        disabled={!cartNotEmpty}
                    />
                },
                {
                    rightsTo: RIGHTS.UNREGISTERED,
                    node: <NavigateButton
                        type="primary"
                        disabled={nextStepDisabled}
                        queryParams={{[QUERY_PARAMS.CART_STEP]: 1}}
                        tooltipTitle={cartHasPrice ? null :
                            <Trans>Nie je možné objednať položky, ktoré sú len na dopyt.</Trans>}
                    >
                        <Trans>Vytvoriť objednávku</Trans>
                    </NavigateButton>
                },
                {
                    rightsFrom: RIGHTS.PENDING,
                    rightsTo: RIGHTS.PENDING,
                    node: <NavigateButton
                        type="primary"
                        disabled={nextStepDisabled}
                        queryParams={{[QUERY_PARAMS.CART_STEP]: 2}}
                        tooltipTitle={cartHasPrice ? null :
                            <Trans>Nie je možné objednať položky, ktoré sú len na dopyt.</Trans>}
                    >
                        <Trans>Vytvoriť objednávku</Trans>
                    </NavigateButton>
                },
                {
                    rightsFrom: RIGHTS.CUSTOMER,
                    node: <NavigateButton
                        type="primary"
                        disabled={nextStepDisabled}
                        queryParams={{[QUERY_PARAMS.CART_STEP]: 3}}
                        tooltipTitle={cartHasPrice ? null :
                            <Trans>Nie je možné objednať položky, ktoré sú len na dopyt.</Trans>}
                    >
                        <Trans>Vytvoriť objednávku</Trans>
                    </NavigateButton>
                }
            ]}
        />;
    }
}

export default CartContentActions;