import React from 'react';
import PropTypes from 'prop-types';
import FormModal from '../../fetch/FormModal';
import generalFailedPC from '../../fetch/generalFailedPC';
import {Button, Divider, Form, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import { GLOBAL_DATA } from '../../../constants/globalData';
import withDataHOC from '../../dataProvider/withDataHOC';
import { createFetchPredictionFillCart } from '../../../backend/apiCalls';
import PredictionMethodSelect from '../../project/PredictionMethodSelect';
import { formItemLayout, mandatoryRule, tailFormItemLayout, numberMandatoryRule } from '../../../constants/form';
import StocksSelect from '../../project/StocksSelect';
import TransferTypesSelect from '../../project/TransferTypesSelect';
import { CustomerDetails, Manufacturers } from '../../../constants/propTypesDefinitions';
import Select from '../../general/Select';
import DiscountTreeSelect from '../../project/DiscountTreeSelect';
import CategoriesTreeSelect from '../../project/CategoriesTreeSelect';
import Tooltip from '../../general/Tooltip';
import { getInputOnChangeEventValue } from '../../../lib/project';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import InputQuantity from '../../project/InputQuantity';

/**
 * @dusan
 */

class PredictionFillCart extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: CustomerDetails.isRequired,
        [GLOBAL_DATA.MANUFACTURERS]: Manufacturers.isRequired,
    };

    render() {
        const {
            [GLOBAL_DATA.CART_CUSTOMER_DETAILS]: customerDetails,
            [GLOBAL_DATA.MANUFACTURERS]: allManufacturers,
            [SESSION_ATTRIBUTES.SETTINGS]: settings,
            reload
        } = this.props;
        const initStocks = settings.default_customer_stocks != null ? settings.default_customer_stocks.map(s => s.id_stock) : [];
        const initTransferTypes = settings.id_selling_types;
        const manufacturerIds = customerDetails.id_manufacturer != null ? customerDetails.id_manufacturer : [];
        const manufacturers = allManufacturers.filter(obj => manufacturerIds.includes(obj.id));
        const disabled = manufacturerIds.length == 0;
        return <FormModal
            disabled={disabled}
            openNode={disabled ? 
                <Tooltip title={<Trans>Organizácia nie je nastavená ako dodávateľ</Trans>}>
                    <Button disabled={true}>
                        <Trans>Skl. objednávka</Trans>
                    </Button>
                </Tooltip> :
                <Button>
                    <Trans>Skl. objednávka</Trans>
                </Button>
            }
            values={{customerDetails, initStocks, initTransferTypes, manufacturers}}
            onFinishSuccessful={reload}
            title={<Trans>Automatická skladová objednávka</Trans>}
            Form={PredictionFillCartFormWrapped}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa navrhnúť objednávku`)}
        />;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
    withDataHOC([ 
        GLOBAL_DATA.CART_CUSTOMER_DETAILS,
        GLOBAL_DATA.MANUFACTURERS
    ])(PredictionFillCart)
);

const FormItem = Form.Item;
class PredictionFillCartForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            customerDetails: CustomerDetails.isRequired,
            initStocks: PropTypes.arrayOf(PropTypes.number.isRequired),
            initTransferTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
            manufacturers: Manufacturers.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterFrequency: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchPredictionFillCart()(formValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading, filterFrequency} = this.state;
        const {values: {customerDetails, initStocks, initTransferTypes, manufacturers}, onClose} = this.props;
        const {getFieldDecorator, setFieldsValue} = this.props.form;

        {getFieldDecorator('id_customer', {initialValue: customerDetails.id})}
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Pokryť spotrebu na</Trans>}
            >
                {getFieldDecorator('prediction_period', {rules: [numberMandatoryRule], initialValue: 3})(
                    <InputQuantity
                        ref={node => this.first = node}
                        step={1}
                        unit={<Trans>mesiacov</Trans>}
                    />
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Objednávací prah</Trans>}
            >
                {getFieldDecorator('quantity_threshold', {initialValue: 'quantity_min'})(
                    <Radio.Group>
                        <Radio.Button value="quantity_min"><Trans>Minimum</Trans></Radio.Button>
                        <Radio.Button value="quantity_bulk"><Trans>Kartón</Trans></Radio.Button>
                        <Radio.Button value="quantity_pallet"><Trans>Paleta</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Výrobca</Trans>}
            >
                {getFieldDecorator('id_manufacturer', {rules: [mandatoryRule], initialValue: customerDetails.id_manufacturer})(
                    <Select
                        isMulti={true}
                        options={manufacturers.map(manufacturer => {
                            return {
                                label: manufacturer.name,
                                value: manufacturer.id,
                            }
                        })}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Rabatová kategória</Trans>}
            >
                {getFieldDecorator('id_discount_group', {rules: [mandatoryRule]})(
                    <DiscountTreeSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Produktová kategória</Trans>}
            >
                {getFieldDecorator('id_product_group', {rules: [mandatoryRule]})(
                    <CategoriesTreeSelect/>
                )}
            </FormItem>
            <Divider orientation="left"><Trans>História pohybov</Trans></Divider>
            <FormItem
                {...formItemLayout}
                label={<Trans>Metóda</Trans>}
            >
                {getFieldDecorator('method', {rules:[mandatoryRule]})(
                    <PredictionMethodSelect/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Spracovať obdobie</Trans>}
            >
                {getFieldDecorator('history_period', {rules: [numberMandatoryRule], initialValue: 24})(
                    <InputQuantity
                        step={1}
                        unit={<Trans>mesiacov</Trans>}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Sklady</Trans>}
            >
                {getFieldDecorator('id_stock', {rules: [mandatoryRule], initialValue: initStocks})(
                    <StocksSelect isMulti={true}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Predajné pohyby</Trans>}
            >
                {getFieldDecorator('id_transfer_type', {rules: [mandatoryRule], initialValue: initTransferTypes})(
                    <TransferTypesSelect isMulti={true}/>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Ignorovať veľké objednávky</Trans>}
            >
                {getFieldDecorator('use_filter', {initialValue: false})(
                    <Radio.Group>
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Ignorovať stav na sklade</Trans>}
            >
                {getFieldDecorator('ignore_inventory', {initialValue: false})(
                    <Radio.Group>
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Iba položky s cenou</Trans>}
            >
                {getFieldDecorator('require_price', {initialValue: false})(
                    <Radio.Group>
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Iba skladové položky</Trans>}
            >
                {getFieldDecorator('filter_frequency', {initialValue: filterFrequency})(
                    <Radio.Group
                        onChange={(ev) => { 
                            const val = getInputOnChangeEventValue(ev);
                            setFieldsValue({max_period: val ? 30 : undefined});
                            this.setState({filterFrequency: val});
                        }}
                    >
                        <Radio.Button value={true}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={false}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Pohyb aspoň raz za</Trans>}
            >
                {getFieldDecorator('max_period', {initialValue: undefined})(
                    <InputQuantity
                        step={1}
                        unit={<Trans>dní</Trans>}
                        disabled={!filterFrequency}
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {loading ? <Trans>Spracováva sa</Trans> : <Trans>Spustiť</Trans>}
                </Button>
            </FormItem>
        </Form>;
    }

}

const PredictionFillCartFormWrapped = Form.create()(PredictionFillCartForm);