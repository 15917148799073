import React from 'react';
import CartOrderInfo from './CartOrderInfo';
import SaveOrder from './cartOrderInfo/SaveOrder';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import RightsWrapper from '../sessionProvider/RightsWrapper';
import {locationHOC} from '../locationProvider/locationHOC';
import NavigateButton from '../general/NavigateButton';
import {getQueryParam, getQueryParamNumber} from '../../lib/url';
import {QUERY_PARAMS} from '../../constants/navigation';
import {RIGHTS} from '../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import { TRANSPORT_TYPE } from '../../constants/transportTypes';
import { isAddressRequired } from '../../lib/project';

/**
 * @fero
 */

class CartOrder extends React.PureComponent {

    render() {
        const {location, rights} = this.props;
        const transportTypeId = getQueryParamNumber(location, QUERY_PARAMS.ID_TRANSPORT_TYPE);
        const paymentMethodId = getQueryParamNumber(location, QUERY_PARAMS.ID_PAYMENT_METHOD);
        const isProxy = getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_PROXY);
        let requiredNotSet = (transportTypeId == null || paymentMethodId == null);
        
        if(isProxy)
        {
            // check if proxy params are set
            const finalCustomerName = getQueryParam(location, QUERY_PARAMS.FINAL_CUSTOMER_NAME);
            const finalUserName = getQueryParam(location, QUERY_PARAMS.FINAL_USER_FULLNAME);
            const finalAddressStreet = getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_STREET);
            const finalAddressZipCode = getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE);
            const finalAddressCity = getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_CITY);
            const finalAddressCountryId = getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_COUNTRY);

            if(finalCustomerName == null || finalUserName == null || finalAddressStreet == null 
                || finalAddressZipCode == null || finalAddressCity == null || finalAddressCountryId == null)
                requiredNotSet = true;
        }
        else
        {
            // standard order
            const invoiceAddressId = getQueryParamNumber(location, QUERY_PARAMS.ID_INVOICE_ADDRESS);
            const deliveryAddressId = getQueryParamNumber(location, QUERY_PARAMS.ID_DELIVERY_ADDRESS);
            const addressRequired = isAddressRequired(rights, transportTypeId);

            if(addressRequired && (invoiceAddressId == null || deliveryAddressId == null))
                requiredNotSet = true;
        }

        return <div className="full-size-height">
            <div className="p-3 d-flex justify-content-between">
                <div>
                    <NavigateButton
                        queryParams={{[QUERY_PARAMS.CART_STEP]: 0}}
                    >
                        <Trans>Obsah košíka</Trans>
                    </NavigateButton>
                </div>
                <div>
                    <RightsWrapper from={RIGHTS.MARKETING}>
                        <SaveOrder
                            disabled={requiredNotSet}
                        />
                    </RightsWrapper>
                    <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                        <NavigateButton
                            type="primary"
                            disabled={requiredNotSet}
                            queryParams={{[QUERY_PARAMS.CART_STEP]: 4}}
                        >
                            <Trans>Zobraziť zhrnutie objednávky</Trans>
                        </NavigateButton>
                    </RightsWrapper>
                </div>
            </div>
            <div className="overflow-y-visible">
                <CartOrderInfo/>
            </div>
        </div>;
    }

}

export default withSessionHOC()(locationHOC(CartOrder));