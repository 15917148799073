import React from 'react';
import PropTypes from 'prop-types';
import {Icon, Steps} from 'antd';
import {RIGHTS} from '../../constants/Rights';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import withSessionHOC from '../sessionProvider/withSessionHOC';
const Step = Steps.Step;

/**
 * @fero
 */

class CartSteps extends React.PureComponent {
    static propTypes = {
        currentStep: PropTypes.number.isRequired,
        changeStep: PropTypes.func.isRequired,
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.node.isRequired,
                icon: PropTypes.string.isRequired,
                Content: PropTypes.func.isRequired,
                rightsTo: PropTypes.number,
            })
        ),
        [SESSION_ATTRIBUTES.RIGHTS]: PropTypes.number,
    };

    onChange = (step) => () => {
        const {changeStep, currentStep} = this.props;
        if(step >= 0 && step < currentStep)
            changeStep(step);
    };

    selectedStep = () => {
        const {currentStep, steps} = this.props;
        const selectedObj = (currentStep >= 0 && currentStep < steps.length) ? steps[currentStep] : (steps.length > 0 ? steps[0] : null);
        const Content = selectedObj != null && selectedObj.Content != null ? selectedObj.Content : null;
        return Content != null ? <Content/> : null;
    };

    render() {
        const {currentStep, steps, [SESSION_ATTRIBUTES.RIGHTS]: rights} = this.props;
        
        return <div className="px-3 full-size-height d-flex flex-column overflow-y-auto">
            <div className="py-3">
                <Steps current={currentStep} size="small">
                { steps.map((obj, key) => {
                        const rightsMax = obj.rightsTo != null ? obj.rightsTo : RIGHTS.ADMIN;
                        const isDone = (rights > rightsMax);
                        return <Step
                            key={key}
                            className={currentStep > key ? "cursor-pointer" : ""}
                            title={obj.title}
                            icon={<Icon type={obj.icon}/>}
                            onClick={this.onChange(key)}
                            status={isDone ? 'finished' : null}
                        />
                    })
                }
                </Steps>
            </div>
            {this.selectedStep()}
        </div>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.RIGHTS])(CartSteps);