import React from 'react';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import ButtonFetch from '../../fetch/ButtonFetch';
import { getQueryUndefinedParamsObject, navigateToParametrized, getQueryParam, getQueryParamNumber } from '../../../lib/url';
import {merge} from '../../../lib/object';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {GLOBAL_DATA} from '../../../constants/globalData';
import generalFailedPC from '../../fetch/generalFailedPC';
import {createFetchCartMakeOrder} from '../../../backend/apiCalls';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

const orderQueryParamsKeys = [
    QUERY_PARAMS.ID_USER,
    QUERY_PARAMS.ID_CUSTOMER,
    QUERY_PARAMS.ID_INVOICE_ADDRESS,
    QUERY_PARAMS.ID_DELIVERY_ADDRESS,
    QUERY_PARAMS.ID_TRANSPORT_TYPE,
    QUERY_PARAMS.ID_PAYMENT_METHOD,
    QUERY_PARAMS.CUSTOMER_REFERENCE,
    QUERY_PARAMS.CUSTOMER_NOTICES,
    QUERY_PARAMS.DEALER_NOTICES,
    QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE,
    QUERY_PARAMS.ORDER_FORM_ID_DST_STOCK,
    QUERY_PARAMS.CART_STEP,
    QUERY_PARAMS.ORDER_FORM_TRADE,
    QUERY_PARAMS.FINAL_ORDER_REFERENCE,
    QUERY_PARAMS.FINAL_CUSTOMER_NAME,
    QUERY_PARAMS.FINAL_USER_FULLNAME,
    QUERY_PARAMS.FINAL_USER_EMAIL,
    QUERY_PARAMS.FINAL_USER_PHONE,
    QUERY_PARAMS.FINAL_USER_LANGUAGE,
    QUERY_PARAMS.FINAL_ADDRESS_STREET,
    QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE,
    QUERY_PARAMS.FINAL_ADDRESS_CITY,
    QUERY_PARAMS.FINAL_ADDRESS_COUNTRY,
];

class SaveOrder extends React.PureComponent {
    orderAttributes = () => {
        const {location} = this.props;
        const useProxy = getQueryParamNumber(location, QUERY_PARAMS.ORDER_FORM_PROXY);
        return {
            id_user: getQueryParam(location, QUERY_PARAMS.ID_USER),
            id_customer: getQueryParam(location, QUERY_PARAMS.ID_CUSTOMER),
            id_invoice_address: getQueryParam(location, QUERY_PARAMS.ID_INVOICE_ADDRESS),
            id_delivery_address: getQueryParam(location, QUERY_PARAMS.ID_DELIVERY_ADDRESS),
            id_transport_type: getQueryParam(location, QUERY_PARAMS.ID_TRANSPORT_TYPE),
            id_payment_method: getQueryParam(location, QUERY_PARAMS.ID_PAYMENT_METHOD),
            customer_reference: getQueryParam(location, QUERY_PARAMS.CUSTOMER_REFERENCE),
            customer_notices: getQueryParam(location, QUERY_PARAMS.CUSTOMER_NOTICES),
            dealer_notices: getQueryParam(location, QUERY_PARAMS.DEALER_NOTICES),
            is_divisible: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_IS_DIVISIBLE),
            id_dst_stock: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_ID_DST_STOCK),
            trade: getQueryParam(location, QUERY_PARAMS.ORDER_FORM_TRADE),
            final_order: useProxy ? {
                order_reference: getQueryParam(location, QUERY_PARAMS.FINAL_ORDER_REFERENCE),
                customer: {
                    name: getQueryParam(location, QUERY_PARAMS.FINAL_CUSTOMER_NAME),
                },
                user: {
                    fullname: getQueryParam(location, QUERY_PARAMS.FINAL_USER_FULLNAME),
                    e_mail: getQueryParam(location, QUERY_PARAMS.FINAL_USER_EMAIL),
                    phone: getQueryParam(location, QUERY_PARAMS.FINAL_USER_PHONE),
                    id_language: getQueryParam(location, QUERY_PARAMS.FINAL_USER_LANGUAGE),
                },
                delivery_address: {
                    street: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_STREET),
                    zip_code: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_ZIP_CODE),
                    city: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_CITY),
                    id_country: getQueryParam(location, QUERY_PARAMS.FINAL_ADDRESS_COUNTRY)
                }
            } : undefined,
        };
    };

    navigateToOrderDetail = (orderId) => {
        const {location} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        reload([GLOBAL_DATA.CART_CURRENT]);
        navigateToParametrized(location, ROUTES.ORDER_DETAILS, merge(
            getQueryUndefinedParamsObject(orderQueryParamsKeys.filter(item => item != QUERY_PARAMS.ID_CUSTOMER)),
            {
                [QUERY_PARAMS.ID_ORDER]: orderId,
                [QUERY_PARAMS.REPORT_TO_GOOGLE]: undefined,
            }
        ));
    };

    render() {
        const {disabled} = this.props;
        const orderAttributes = this.orderAttributes();
        return <ButtonFetch
            type="primary"
            values={{}}
            disabled={disabled}
            Failed={generalFailedPC(t`Nepodarilo sa uložiť objednávku.`)}
            fetchFunction={createFetchCartMakeOrder(orderAttributes)}
            onFinish={this.navigateToOrderDetail}
        >
            <Trans>Uložiť</Trans>
        </ButtonFetch>;
    }

}

export default locationHOC(withDataHOC([GLOBAL_DATA.RELOAD_DATA])(SaveOrder));