import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import {navigateToParametrized} from '../../../lib/url';
import {Button, Form, Input} from 'antd';
import {Trans, t} from '@lingui/macro';
import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {createFetchCartMakeRequest} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout} from '../../../constants/form';
import InputText from '../../general/InputText';
import locationHOC from '../../locationProvider/locationHOC';
const FormItem = Form.Item;
const TextArea = Input.TextArea;

/**
 * @fero
 */

class MakeRequestCustomer extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        reload: PropTypes.func.isRequired,
    };

    render() {
        const {location, reload, disabled} = this.props;
        return <FormModal
            disabled={disabled}
            openNode={
                <Button
                    disabled={disabled}
                >
                    <Trans>Vytvoriť dopyt</Trans>
                </Button>
            }
            values={{}}
            onFinishSuccessful={(result) => {
                navigateToParametrized(location, ROUTES.REQUEST_DETAILS, {[QUERY_PARAMS.ID_REQUEST]: result});
                reload();
            }}
            title={<Trans>Vytvorenie dopytu</Trans>}
            Form={MakeRequestCustomerFormWrapped}
            fetchFunc={createFetchCartMakeRequest()}
            Response={null}
            Failed={generalFailedPC(t`Dopyt sa nepodarilo odoslať.`)}
        />;
    }
}

export default locationHOC(MakeRequestCustomer);

class MakeRequestCustomerForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({}).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.first != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                createFetchCartMakeRequest()(formValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Vaše číslo dopytu</Trans>}>
                {getFieldDecorator('customer_reference', {})(
                    <InputText/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Poznámka</Trans>}>
                {getFieldDecorator('customer_notices', {})(
                    <TextArea min={2} max={20}/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Trans>Poznámky k jednotlivým produktom z košíka budú prenesené do dopytu.</Trans>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    <Trans>Vytvoriť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const MakeRequestCustomerFormWrapped = Form.create()(MakeRequestCustomerForm);