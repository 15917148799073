import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';
import {createFetchCartRemoveItems} from '../../../backend/apiCalls';

/**
 * @fero
 */

class CartClear extends React.PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        customerId: PropTypes.number,
        reload: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired),
    }
    render() {
        const {disabled, customerId, reload, selectedItemsIds} = this.props;
        const selectedItemsChecked = selectedItemsIds != null && selectedItemsIds.length > 0 ? selectedItemsIds : undefined;
        return <FormModal
            disabled={disabled}
            openNode={
                <Button
                    type="danger"
                    disabled={disabled}
                >
                    { selectedItemsChecked != null ? 
                        <Trans>Vymazať vybrané položky</Trans> : 
                        <Trans>Vyprázdniť košík</Trans>
                    }
                </Button>
            }
            values={{}}
            onFinishSuccessful={reload}
            title={<Trans>Vyprázdnenie košíka</Trans>}
            Form={generalNoParameterFormPC(
                selectedItemsChecked != null ? <Trans>Chcete vymazať vybrané položky?</Trans> : <Trans>Chcete vyprázdniť košík?</Trans>,
                createFetchCartRemoveItems(customerId, selectedItemsChecked),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa vyprázdniť košík.`)}
        />;
    }
}

export default CartClear;