import React from 'react';
import PropTypes from 'prop-types';
import {Trans} from '@lingui/macro';

/**
 * @dusan
 */

class SpecialTransportIcon extends React.PureComponent {
    static propTypes = {
        flag: PropTypes.number,
        className: PropTypes.string,
    };

    static defaultProps = {
        className: ""
    };

    render() {
        const {flag, className} = this.props;
        if(flag == null || flag == 0)
            return null;
        
        return <div className={"table-subdata color-red " + className}><Trans>neštandardná doprava</Trans></div>;
    }
}

export default SpecialTransportIcon;